import TableWrapper from '../../../components/table-wrapper';
import {columns} from './columns';
import React from 'react';
import { useGetLabelsMutation } from '../api-slice';
import AddLabelView from '../add';

const LabelsListView = () => {

  return <>
    <TableWrapper
      pagination={'online'}
      api={useGetLabelsMutation}
      title={'مدیریت برچسب‌ها'}
      AddComponent={AddLabelView}
      columns={columns}
      uniqKey={'label'}
      filterOptions={[
        {
          name: 'search',
          placeholder: 'جست‌جو',
          type: 'string'
        },
        {
          name: 'is_active',
          type: 'select',
          placeholder: 'وضعیت',
          options: [
            { name: 'همه', value: undefined },
            { name: 'فعال', value: true },
            { name: 'غیر فعال', value: false },
          ]
        },
      ]}
    />
  </>
};

export default LabelsListView;