import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEditUserLabelMutation } from './access-api-slice';
import CustomSwitch from '../../../components/switch';
import Modal from '../../../components/modal';

const t = {
  header: 'ویرایش دسترسی',
  view: {
    label: 'مشاهده قبوض ',
    placeholder: 'امکان مشاهده قبوض این برچسب',
  },
  add: {
    label: 'ایجاد قبض ',
    placeholder: 'امکان ایجاد قبض جدید در این برچسب',
  },
  pay: {
    label: 'پرداخت قبوض',
    placeholder: 'امکان پرداخت تکی و یکجا قبوض',
  },
  submit: 'ثبت دسترسی',
  cancel: 'انصراف',
};

interface iForm {
  label?: number;
  user_mobile_number: string;
  has_view_permission: boolean;
  has_payment_permission: boolean;
  has_insert_permission: boolean;
}

const EditPermissionsView = ({ data ,renew}: any) => {
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState<iForm>({
    label: data?.label,
    user_mobile_number: data?.user?.mobile_number || data?.user?.email || data?.user?.username,
    has_view_permission: data.has_view_permission,
    has_payment_permission: data.has_payment_permission,
    has_insert_permission: data.has_insert_permission,
  });
  const [error, setError] = useState<iForm>({
    label: undefined,
    user_mobile_number: undefined,
    has_view_permission: undefined,
    has_payment_permission: undefined,
    has_insert_permission: undefined,
  });
  const [editUserLabel, { isLoading }] = useEditUserLabelMutation();

  const handleForm = ({ value, name }) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async () => {
    try {
      const id = form?.label;
      const body = { ...form };
      delete body.label;
      if (form.label) {
        const res = await editUserLabel({ id, body }).unwrap();
        if (res?.status) {
          renew();
          toast.success(res?.message);
          setOpen(!open)
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validationHandler = async (onSubmit: any) => {
    let hasError = false;
    let errors: any = {};
    Object.keys(form).map((key: any) => {
      if (validator(form, key)) {
      } else {
        errors = { ...errors, [key]: 'این مقدار اجباری میباشد!' };
        hasError = true;
      }
    });
    setError({ ...errors });
    if (!hasError)
      onSubmit();
  };

  const validator = <T, K extends keyof T>(obj: T, key: K) => {
    return obj[key] !== undefined;
  };

  useEffect(() => {
    if (!open)
      setForm({
        label: data?.label,
        user_mobile_number: data?.user?.mobile_number || data?.user?.email || data?.user?.username,
        has_view_permission: data.has_view_permission,
        has_payment_permission: data.has_payment_permission,
        has_insert_permission: data.has_insert_permission,
      })
  }, [open,data]);

  return <>
    <button
      className="parag-md-b h-[27px] px-5 bg-primary rounded-full text-white"
      onClick={() => setOpen(!open)}
    >
      ویرایش
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={'bg-white border w-[700px] h-[430px] border-gray-200 rounded-lg mt-16 mx-12 py-5 px-10'}>
        <h1 className="mt-4">{t.header}</h1>
        <div className="p-4 flex justify-between mt-4">
          <div>
            <h3>{t.view.label}</h3>
            <label className="text-sm">{t.view.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_view_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_view_permission' })} />
        </div>
        <div className="p-4 flex justify-between">
          <div>
            <h3>{t.pay.label}</h3>
            <label className="text-sm">{t.pay.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_payment_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_payment_permission' })} />
        </div>
        <div className="p-4 flex justify-between">
          <div>
            <h3>{t.add.label}</h3>
            <label className="text-sm">{t.add.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_insert_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_insert_permission' })} />
        </div>
        <div className="p-4 flex justify-end mt-4">
          <button
            className="bg-[#181C32] text-white rounded-3xl py-2 px-6 mx-2"
            disabled={isLoading} onClick={() => validationHandler(onSubmit)}
          >{t.submit}</button>
          <button
            onClick={() => setOpen(!open)} className="border border-[#181C32] text-[#181C32] rounded-3xl py-2 px-6"
          >{t.cancel}</button>
        </div>
      </div>
    </Modal>
  </>;
};

export default EditPermissionsView;