import { ReactNode } from 'react';

interface ModalProps {
  open: boolean;
  onClose: any;
  children: ReactNode;
}

export default function Modal({ open = false, onClose, children }: ModalProps) {
  return (
    <>
      {open ? (
        <>
          <div onClick={() => {onClose()}}
               className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={onClose}></div>
        </>
      ) : null}
    </>
  );
}
