import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

const month = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر','آبان','آذر','دی','بهمن','اسفند']

function ApexChart({data,type}:any) {
  const series = [
    {
      name: type?.name,
      data: data?.map((item: any) => item?.total_amount || 0) || []
    },
  ];
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      fontFamily: 'Vazirmatn',
      foreColor: '#718096',
      offsetX: -10,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 9,
        borderRadius: 3,
        colors: {
          backgroundBarColors: ['#EDF2F7'],
          backgroundBarRadius: 10,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data?.map((item: any) => month[item?.jalali_month-1]) || [],
      offsetY: -8,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '8px',
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '8px',
          fontWeight: 600,
        },
      },
    },
    fill: {
      opacity: 1,
      colors: data?.map((item: any) => item?.color_code || 0) || [],
    },

    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + 'ربال';
        }
      }
    }
  };

  return (
    <div id="chart">
      {data.length?<ReactApexChart options={options} series={series} height={230} type="bar" />:''}
    </div>
  );
}

export default ApexChart;
