import React, { useState } from 'react';
import { PrinterIcon } from 'src/assets/icons';
import s from './popover.module.css';
import Modal from 'src/components/modal';
import BillReceipt from './receipt';

export default function ReportsBillPopOver({ id }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <BillReceipt id={id} onClose={() => setOpen(false)} />
      </Modal>
      {isOpen && (
        <ul className={`paragraph-sb ${s.itemsPopover} parag-md-b`}>
          <li
            onClick={() => {
              setOpen(true);
              setIsOpen(false);
            }}
          >
            <PrinterIcon />
            پرینت قبض
          </li>
        </ul>
      )}
    </>
  );
}
