import qs from 'qs';
import { apiSlice } from 'src/services/api-slice';

export const drawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    billTypes: builder.query({
      query: () => {
        return {
          url: '/bill/types/',
        };
      },
      providesTags: ['Bills'],
      transformResponse: ({ data }) => data,
    }),
    billRenew: builder.query({
      query: (id) => ({
        url: `/bill/renew/${id}`,
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
    billDetail: builder.query({
      query: (id) => ({
        url: `/bill/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
    createBill: builder.mutation({
      query: (body) => ({
        url: '/bill/create/',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Bills'],
    }),
    billActivation: builder.mutation({
      query: (body) => ({
        url: `/bill/activation/${body.id}`,
        method: 'PATCH',
        body: { active: body.active },
      }),
      invalidatesTags: ['Bills'],
    }),
    createBillsFactor: builder.query({
      query: (params) => {
        return {
          url: `/label/create-bills-factor/?${qs.stringify({...params}, {arrayFormat: 'repeat'})}`
        };
      },
      providesTags: ['Bills'],
    }),
    getInquiryHistory: builder.mutation({
      query: ({ id, params }) => ({
        url: `/bill/inquiry_history/${id}`,
        method: 'GET',
        params: params,
      }),
    }),
    billPayment: builder.mutation({
      query: (body) => ({
        url: '/payment/bill-payment/',
        method: 'POST',
        body: { ...body },
      }),
    }),
  })
});

export const {
  useBillTypesQuery,
  useLazyBillRenewQuery,
  useBillDetailQuery,
  useCreateBillMutation,
  useBillActivationMutation,
  useLazyCreateBillsFactorQuery,
  useGetInquiryHistoryMutation,
  useBillPaymentMutation
} = drawApiSlice;