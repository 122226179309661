import { apiSlice } from 'src/services/api-slice';
import qs from 'qs';
import APIs from '../../services/APIs';

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestInfo: builder.mutation({
      query: (arg) => ({
        url: `${APIs.dashboard.info}?${qs.stringify({...arg}, {arrayFormat: 'repeat'})}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useRequestInfoMutation } = dashboardApiSlice;
