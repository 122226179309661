import { apiSlice } from 'src/services/api-slice';
import APIs from 'src/services/APIs';

export const uploadExcelApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploads: builder.mutation({
      query: (params) => {
        return {
          url: "bulk-insert-bill/list",
          params: { ...params },
        };
      },
    }),
    bulkInsertBillDetail: builder.query({
      query: (id) => ({
        url: APIs.bulkInsertBill.detail(id),
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
  }),
});

export const { useUploadsMutation, useLazyBulkInsertBillDetailQuery } = uploadExcelApiSlice;
