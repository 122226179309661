import React from 'react';
import TableWrapper from '../../../components/table-wrapper';
import {columns} from './columns';
import { useHistoryBillMutation } from '../api-slice';
import useLabels from '../../../hooks/use-labels';
import { useBillTypesQuery, useLazyCreateBillsFactorQuery } from '../../management/management-api-slice';

const ReportBillListView = () => {
  const { labels } = useLabels();
  const { data: billsTypes = [] } = useBillTypesQuery({});
  const [createBillsFactor] = useLazyCreateBillsFactorQuery();

  return <>
    <TableWrapper
      pagination={'online'}
      api={useHistoryBillMutation}
      title={'گزارشات قبوض'}
      columns={columns}
      uniqKey={'label'}
      downloadApi={createBillsFactor}
      downloadKey={'bill_history'}
      filterOptions={[
        {
          name: 'label_ids',
          placeholder: 'برچسب',
          type: 'multiselect',
          options:labels.map((e) => {return { name:e.label,value:e.value }})
        },
        {
          name: 'bill_type_id',
          type: 'multiselect',
          placeholder: 'نوع قبض',
          options: billsTypes.map((e) => {return { name:e.name,value:e.id }})
        },
        {
          name: 'date_from',
          placeholder: 'ازتاریخ',
          type: 'date',
        },
        {
          name: 'date_to',
          placeholder: 'تاتاریخ',
          type: 'date',
        },
        {
          name: 'search',
          placeholder: 'شناسه و عنوان قبض',
          type: 'string',
        },
      ]}
    />
  </>
};

export default ReportBillListView;