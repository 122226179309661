import { DASHBOARD_SIDEBAR_LINKS } from '../../utils/navigation';
import classNames from 'classnames';
import s from './sidebar.module.css';
import SidbarLinks from './sidebar-links';

export default function Sidebar({open,setOpen}:any) {
  return (
    <div className={classNames(open ? 'w-[220px]' : 'w-[85px]', s.sidebar)}>
      <div className={s.sidebarHeader}>
        <img
          src="/images/arrow-right.svg"
          alt=""
          width={28}
          onClick={() => setOpen(!open)}
          className={classNames(!open ? 'rotate-180' : '', s.sidebarHeaderArrow)}
        />
          <img
            src="/images/pasargad-logo-fa.png"
            alt="logo"
            width={!open?40:58}
            height={!open?40:56}
            className={classNames(open ? 'mr-18' : '', 'm-auto')}
          />
      </div>
      <div className="pr-3 pl-7">
        <ul className={s.sidebarItems}>
          {DASHBOARD_SIDEBAR_LINKS.map((items) => {
            return <SidbarLinks key={items.key} items={items} open={open} />;
          })}
        </ul>
      </div>
    </div>
  );
}
