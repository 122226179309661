import Joi from 'joi';

const labelSchema = Joi.object({
  name: Joi.string().required().label('name').messages({
    'string.empty': `نام برچسب الزامیست`,
    'any.required': `نام برچسب الزامیست`,
    'string.pattern.base': `نام برچسب صحیح نمی‌باشد`,
  }),
  description: Joi.string().allow('', null),
  is_active: Joi.boolean().allow('', null),
});
export default labelSchema;
