import { useRef, useState } from 'react';
import { DownloadIcon, PrinterIcon, RenewIcons } from 'src/assets/icons';
import s from './popover.module.css';
import CustomSwitch from 'src/components/switch';
import { useBillActivationMutation, useLazyBillRenewQuery } from '../management-api-slice';
import Modal from 'src/components/modal';
import BillReceipt from './receipt';
import { toast } from 'react-toastify';
import { APP_URL } from 'src/utils/constants';

export default function ManagementPopOver({ close, isActive, id }: any) {
  const [active, setActive] = useState(isActive);
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [billActivation] = useBillActivationMutation();
  const [billRenew] = useLazyBillRenewQuery();
  const componentRef = useRef();

  const handleRenew = async () => {
    setIsOpen(false);
    try {
      await billRenew(id).unwrap();
      toast.success('استعلام با موفقیت انجام شد');
    } catch (error) {
    }
  };

  const handleActive = async (active) => {
    setActive(!active);
    const body = { id, active: true };
    try {
      await billActivation(body).unwrap();
      close()
    } catch (error) {
    }
  };

  const handleDownload = (type: string,name:string) => {
    const access = localStorage.getItem('access');
    setIsOpen(false);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access,
      },
    };

    fetch(`${APP_URL}api/bill/bill-${type}/${id}`, requestOptions)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', type === 'pdf' ? `${name}.pdf` : `${name}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: 'Something Went Wrong', err });
      });
  };

  const handlePrint = () => {
    // Set a delay before initiating the printing process
    setTimeout(() => {
      window.print();
    }, 500);// Adjust the delay as needed
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <BillReceipt id={id} onClose={() => setOpen(false)} />
      </Modal>
      {isOpen && (
        <ul className={`paragraph-sb ${s.itemsPopover} parag-md-b`}>
          <li
            onClick={() => {
              setOpen(true);
              setIsOpen(false);
            }}
          >
            <button className="flex" onClick={handlePrint}>
              <PrinterIcon />
              <span className="mr-2">
                پرینت قبض
              </span>
            </button>
            <div style={{ display: 'none' }}>
              <div ref={componentRef} className="absolute w-[100vw] h-[100vh] bg-white">
                <BillReceipt id={id} onClose={() => setOpen(false)} />
              </div>
            </div>
          </li>
          <li onClick={handleRenew}>
            <RenewIcons />
            استعلام مجدد
          </li>
          <li onClick={() => handleDownload('excel','آخرین استعلام')}>
            <DownloadIcon />
            دانلود اکسل آخرین استعلام
          </li>
          <li onClick={() => handleDownload('pdf','آخرین استعلام')}>
            <DownloadIcon />
            دانلود PDF آخرین استعلام
          </li>
          <li>
            <CustomSwitch enabled={active} onChange={handleActive} title={'فعال / غیر فعال'} />
          </li>
        </ul>
      )}
    </>
  );
}
