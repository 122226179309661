import { apiSlice } from 'src/services/api-slice';
import APIs from 'src/services/APIs';

export const labelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLabels: builder.mutation({
      query: (params) => {
        return {
          method: 'GET',
          url: `label/`,
          params: { ...params },
        };
      },
    }),
    label: builder.query({
      query: (id) => ({
        url: APIs.labels.item(id),
        method: 'GET',
      }),
      providesTags: ['Labels'],
    }),
    registerLabel: builder.mutation({
      query: (body) => ({
        url: `label/register/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Labels'],
    }),
    updateLabel: builder.mutation({
      query: (body) => ({
        url: `label/edit/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Labels'],
    }),
    labelsDropdown: builder.query({
      query: () => ({
        url: `label/dropdown/`,
        method: 'GET',
      }),
      providesTags: ['Labels'],
    }),
  }),
});

export const {
  useGetLabelsMutation,
  useLazyLabelQuery,
  useLazyLabelsDropdownQuery,
  useRegisterLabelMutation,
  useUpdateLabelMutation,
} = labelsApiSlice;
