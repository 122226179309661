import { apiSlice } from 'src/services/api-slice';
import APIs from 'src/services/APIs';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: APIs.auth.login,
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: APIs.auth.logout,
        method: 'POST',
        body,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: APIs.auth.verify_otp,
        method: 'POST',
        body,
      }),
    }),
    requestOtp: builder.mutation({
      query: ({ type , body }) => ({
        url: APIs.auth.request_otp(type),
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: APIs.auth.change_password,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useVerifyOtpMutation, useRequestOtpMutation, useChangePasswordMutation } = authApiSlice;
