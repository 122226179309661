import { useNavigate } from 'react-router-dom';
import ApexChartDonut from './apex-chart-donut';
import ChartBar from './chart-bar';
import ChartPie from './chart-pie';
import Contant from './contact';
import s from './dashboard.module.css';
import { useRequestInfoMutation } from './dashboard-api-slice';
import { useEffect, useState } from 'react';
import { priceFormatter } from '../../utils/helpers';
import { useAppSelector } from '../../redux/hooks';
import { selectActiveLabel } from '../labels/labels-slice';

function Dashboard() {
  const activeLabel = useAppSelector(selectActiveLabel);
  const navigate = useNavigate();
  const [requestInfo] = useRequestInfoMutation();
  const [data, setData] = useState({
    base_info: undefined,
    ready_to_pay_chart_info: undefined,
    bill_payment_per_month_chart: undefined,
    unpaid_bill_counts_chart: undefined,
  });

  // has_export

  async function getChartsData() {
    let arg = {
      base_info: 'True',
      ready_to_pay_chart_info: 'True',
      bill_payment_per_month_chart: 'True',
      unpaid_bill_counts_chart: 'True',
      bill_payment_per_month_chart_excel: 'False',
      label_ids: [activeLabel?.value],
    };

    try {
      const res = await requestInfo(arg).unwrap();
      setData(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (activeLabel?.value)
      getChartsData();
  }, [activeLabel?.value]);

  const billsItems = [
    {
      id: '1',
      title: 'جمع قبوض پرداخت شده',
      label: `${priceFormatter(data.base_info?.paid_bills_total_amount || '0')} ریال`,
      icon: '/images/wallet-add.svg',
    },
    {
      id: '2',
      title: 'تعداد کل قبوض',
      label: data.base_info?.bills_count || '-',
      icon: '/images/task-square.svg',
    },
    {
      id: '3',
      title: 'موجودی کیف پول',
      label: `${priceFormatter(data.base_info?.wallet_balance || '0')} ریال`,
      icon: '/images/empty-wallet.svg',
    },
  ];

  const itemsLogo = [
    {
      id: '1',
      icon: '/images/3.svg',
      alt: 'گاز',
      path: '/management?gas',
      disabled: false,
    },
    {
      id: '2',
      icon: '/images/2.svg',
      alt: 'برق',
      path: '/management?power',
      disabled: false,
    },
    {
      id: '3',
      icon: '/images/7.svg',
      alt: 'تلفن ثابت',
      path: '/management?mobile',
      disabled: false,
    },
    {
      id: '4',
      icon: '/images/5.svg',
      alt: 'شهرداری',
      path: '/management?city',
      disabled: true,
    },
    {
      id: '5',
      icon: '/images/1.svg',
      alt: 'آب',
      path: '/management?water',
      disabled: false,
    },
  ];

  return (
    <div className={s.dashboard}>
      <div className={s.sectionBill}>
        <div className={s.billDetail}>
          {billsItems.map((items: any) => (
            <div key={items.id} className={s.detailItems}>
              <p className="parag-lg-b text-gray-700">{items.title}</p>
              <div className={s.detailItem}>
                <span className="parag-md-b text-gray-400">{items.label}</span>
                <img className="relative bottom-1.5" src={items.icon} alt={items.title} width={35} height={35} />
              </div>
            </div>
          ))}
        </div>
        <div className={s.bills}>
          <div className={s.billsHeader}>
            <p className="parag-xl-b text-gray-800">افزودن قبض جدید</p>
            <button onClick={() => navigate('/management')} className={`${s.billsButton} parag-b`}>
              مدیریت قبوض
            </button>
          </div>
          <div className={s.billItems}>
            {itemsLogo.map((item: any) => (
              <div key={item.id} className={item.disabled ? s.billsLogoDisable : s.billsLogo}
                   onClick={() => item.disabled ? '#' : navigate(item.path)}>
                <img src={item.icon} alt={item.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ChartBar data={data?.bill_payment_per_month_chart || []} />
      <ChartPie data={data?.ready_to_pay_chart_info || []} />
      <div className="col-span-5 space-y-3.5">
        <div className={s.chartDonut}>
          <ApexChartDonut data={data?.unpaid_bill_counts_chart || []} />
        </div>
        <Contant />
      </div>
    </div>
  );
}

export default Dashboard;
