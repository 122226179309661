import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { CloseTwoIcon } from 'src/assets/icons';
import TextMessage from 'src/components/text-message';
import {
  useLazyLabelQuery,
  useLazyLabelsDropdownQuery,
  useRegisterLabelMutation,
  useUpdateLabelMutation,
} from '../api-slice';
import CustomSwitch from 'src/components/switch';
import labelSchema from '../schema';
import { setLabels } from '../labels-slice';
import { useAppDispatch } from 'src/redux/hooks';
import Modal from '../../../components/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const defaultValues = {
  name: '',
  description: '',
  is_active: true,
};

interface formData {
  name: string;
  description: string;
  is_active: boolean;
}

export default function AddLabelView({ item, renew, btnClass }: any) {
  const params = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [enabled, setEnabled] = useState(true);
  const dispatch = useAppDispatch();
  const [registerLabel, { error }] = useRegisterLabelMutation();
  const [updateLabel] = useUpdateLabelMutation();
  const [trigger, { data }] = useLazyLabelQuery(item?.label_id);
  const [getlabelsDropdown] = useLazyLabelsDropdownQuery();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<formData>({
    resolver: joiResolver(labelSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (params.search === '?open' && !item){
      setOpen(!open)
      navigate('/labels')
    }

  }, [params.search]);

  useEffect(() => {
    item && trigger(item?.label_id);
    return () => {
    };
  }, []);

  useEffect(() => {
    setEnabled(getValues('is_active'));
  }, [getValues('is_active')]);

  useEffect(() => {
    if (data) {
      const body = {
        name: data?.data?.label_name,
        description: data?.data?.label_description,
        is_active: data?.data?.is_active,
      };
      reset(body);
    }
  }, [data, reset]);

  async function handleRegisterLabel(value: any) {
    let body = {
      name: value.value?.name || value.name,
      description: value.value?.description || value.description,
      is_active: enabled,
      id: item?.label_id,
    };

    if (item) {
      try {
        await updateLabel(body).unwrap();
        const res = await getlabelsDropdown({}).unwrap();
        if (res?.data) handleLabels(res?.data);
        toast('برچسب شما با موفقیت ویرایش شد');
        renew();
        setOpen(!open);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await registerLabel(body).unwrap();
        const res = await getlabelsDropdown({}).unwrap();
        if (res?.data?.length)
          handleLabels(res?.data);
        toast.success('برچسب شما با موفقیت اضافه شد');
        if (renew)
          renew();
        setOpen(!open);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleLabels = (data) => {
    const transformLabels = data?.map((item: any) => {
      return {
        value: item.label_id,
        label: item.label_name,
      };
    });
    dispatch(setLabels(transformLabels));
    localStorage.setItem('labels', JSON.stringify(transformLabels));
  };

  useEffect(() => {
    if (!open)
      reset()
  }, [open]);

  return <>
    <button
      className={
        btnClass ?
          btnClass :
          `bg-primary text-white btn-rounded ${item?.label_id ? 'h-[27px]' : ''}`
      }
      onClick={() => setOpen(!open)}
    >
      {item?.label_id ? 'ویرایش برچسب' : '+ افزودن برچسب جدید'}
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="w-[500px] bg-white rounded-lg">
        <div className="flex justify-center items-center text-gray-650 relative h-[45px] bg-slate-100 rounded-t-lg">
          <span className="paragraph-bx">{!item?.label_id ? 'افزودن برچسب جدید' : 'ویرایش برچسب'}</span>
          <div onClick={() => setOpen(!open)}
               className="absolute -top-3.5 -right-3.5 cursor-pointer bg-white rounded-full p-2 shadow">
            <CloseTwoIcon />
          </div>
        </div>
        <form onSubmit={handleSubmit(handleRegisterLabel)} className="py-2">
          <div className="grid grid-cols-2 gap-4 py-6 px-8">
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    className="h-10 w-[200px] parag-lg-b text-gray-500 placeholder:parag-lg-b bg-white border border-gray-100"
                    {...field}
                    type="text"
                    placeholder="نام برچسب"
                  />
                )}
              />
              {errors.name?.message && <TextMessage msg={errors.name?.message} type="error" />}
            </div>
            <div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <input
                    className="h-10 w-[200px] parag-lg-b text-gray-500 placeholder:parag-lg-b bg-white border border-gray-100"
                    {...field}
                    type="text"
                    placeholder="توضیحات"
                  />
                )}
              />
              {errors.description?.message && <TextMessage msg={errors.description?.message} type="error" />}
            </div>
            <div>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <CustomSwitch
                    {...field}
                    defaultChecked={getValues('is_active')}
                    title="فعال"
                    enabled={enabled}
                    onChange={setEnabled}
                  />
                )}
              />
              {errors.is_active?.message && <TextMessage msg={errors.is_active?.message} type="error" />}
            </div>
          </div>
          {
            // @ts-ignore
            error && <TextMessage msg={error?.data?.message} type="error" />
          }
          <div className="pb-5 pl-8 text-left">
            <button className="parag-lg-b bg-primary text-white rounded-full w-[100px] h-[35px] ml-1.5">
              {item?.label_id ? 'ویرایش' : 'افزودن'}
            </button>
            <button
              onClick={() => setOpen(!open)}
              type="submit"
              className="parag-lg-b border-2 border-primary bg-white text-primary h-[35px] rounded-full w-[100px]"
            >
              انصراف
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </>;
}
