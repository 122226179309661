import { apiSlice } from 'src/services/api-slice';
import APIs from 'src/services/APIs';

export const labelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    notifications: builder.query({
      query: (params) => {
        return {
          url: APIs.notifications.list,
          params: params,
          method: 'GET',
        };
      },
      providesTags: ['Notif'],
    }),
    getNotifications: builder.mutation({
      query: (params:any) => ({
        url: APIs.notifications.list,
        params: params,
        method: 'GET',
      }),
    }),
    notification: builder.query({
      query: (id) => ({
        url: APIs.notifications.item(id),
        method: 'GET',
      }),
      providesTags: ['Notif'],
    }),
  }),
});

export const { useNotificationsQuery, useGetNotificationsMutation, useNotificationQuery } = labelsApiSlice;
