import { useEffect, useState } from 'react';
import { CloseTwoIcon, LoadingIcon } from 'src/assets/icons';
import { useBillDetailQuery } from '../api-slice';
import s from './bill-receipt.module.css';

const BillReceipt = ({ onClose, id }: any) => {
  const [structures, setStructures] = useState([]);
  const [details, setDetails] = useState([]);
  const { data: billDetail, isLoading } = useBillDetailQuery(id);

  useEffect(() => {
    if (billDetail) {
      setStructures(Object.values(billDetail?.data?.structure));
      setDetails(Object.values(billDetail?.data?.response));
    }
    return () => {};
  }, [billDetail]);

  return (
    <div className={s.billReceipt}>
      <div className={s.modalHeader}>
        <div onClick={onClose} className={s.iconClose}>
          <CloseTwoIcon />
        </div>
        {/* <div className={s.billIcon}>
          <img src="/images/2 537.png" alt="قبض گاز" />
        </div> */}
        <span className="paragraph-se">رسید قبض</span>
      </div>
      <div className="py-3 px-5">
        <div className={s.detail}>
          {isLoading ? (
            <p className="flex h-72 justify-center items-center">
              <LoadingIcon />
            </p>
          ) : (
            <>
              {structures.length > 0 &&
                details.length > 0 &&
                structures.map((item: any, idx) => {
                  return (
                    <div className="flex justify-between items-center px-2">
                      {item && <span className="text-[#8F96BE] text-sm py-1">{item}</span>}
                      {item && <span className="text-[#555555] text-sm">{details[idx]}</span>}
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillReceipt;
