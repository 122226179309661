/* eslint-disable import/no-anonymous-default-export */
export default {
  auth: {
    login: 'token/',
    change_password: 'change-password/',
    logout: 'logout/',
    refresh: 'token/refresh/',
    verify_otp: 'otp-verify/',
    request_otp: (type:'login'|'change_password') => `send-otp/${type}`,
  },
  dashboard:{
    info:'dashboard/dashboard-info'
  },
  profile: {
    info: 'userinfo',
  },
  labels: {
    list: `label/`,
    item: (id: any) => `label/${id}`,
    add: `label/register/`,
    update: (id: number) => `label/edit/${id}`,
    dropdown: `label/dropdown/`,
  },
  bulkInsertBill: {
    upload: `bulk-insert-bill/`,
    list: `bulk-insert-bill/list`,
    detail: (id: number) => `bulk-insert-bill/detail/${id}`,
  },
  notifications: {
    list: `notification/list/`,
    item: (id: number) => `notification/detail/${id}`,
  },
  deviceTypes: {
    list: (params: string) => `device-type/${params}`,
    item: (id: number) => `device-type/${id}`,
    update: (id: number) => `device-type/edit/${id}`,
    add: `device-type/register/`,
    dropdown: `device-type/dropdown/`,
  },
};
