import { apiSlice } from 'src/services/api-slice';
import APIs from 'src/services/APIs';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestProfile: builder.mutation({
      query: (params) => ({
        url: APIs.profile.info,
        params: params,
        method: 'GET',
      }),
    }),
  }),
});

export const { useRequestProfileMutation } = profileApiSlice;
